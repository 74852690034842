@import "./variables";

.app-form-container {
  display: flex;
  flex-direction: column;
  gap: var(--chakra-space-12-8);
  width: 60%;
}

.app-form-field-array-container {
  display: flex;
  flex-direction: column;
  gap: var(--chakra-space-12-8);
}

.app-form-field-container {
  margin-bottom: var(--chakra-space-12-8);
  &:last-child {
    margin-bottom: 0;
  }
}

.dashed-left-border {
  border-left: 1px dashed $color-link-hover-color;
  padding-left: var(--chakra-space-6-4);
  margin-left: var(--chakra-space-6-4);
}

.app-form-field-container-small {
  margin-bottom: 1.2rem;
}
