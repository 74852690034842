@import "_variables.scss";
@import "mixins";
@import "hero";
@import "topmenu";
@import "buttons";
@import "forms";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";

// Bootstrap overrides for font size
.form-control {
  font-size: 16px;
}

.btn {
  --bs-btn-font-size: 14px;
}
.btn-link {
  text-decoration: none;
  color: var(--fxt-btn-secondary-clr);
  border: none;
  background-color: transparent;
  &:hover {
    color: var(--fxt-btn-link-hover-clr);
    text-decoration: underline;
    text-decoration-color: var(--fxt-btn-secondary-clr);
    background-color: transparent;
  }
}

.form-control {
  border-radius: var(--chakra-space-3-2);
  border-color: #{$fast-gray-2};
  &:focus {
    border-color: $fast-blue-2;
    box-shadow: none;
  }
}

.form-control:disabled,
.form-control[readonly] {
  background: #{$color-gray-200};
  border-color: #{$color-gray-200};
  color: #{$color-gray-600};
}

/* ----------------------------------------------------------------------------------- */
/*  Dark Mode for bootstrap                                                            */
/* ----------------------------------------------------------------------------------- */
@media (prefers-color-scheme: dark) {
  .btn {
    --bs-btn-color: #{$color-default-white};
    --bs-btn-hover-bg: #{$color-gray-500};
    --bs-btn-hover-border-color: #{$color-gray-500};
    --bs-btn-bg: #{$color-gray-0};
  }
  .btn:hover {
    --bs-btn-hover-color: #{$color-default-white};
    --bs-btn-hover-bg: #{$color-gray-500};
    --bs-btn-hover-border-color: #{$color-gray-500};
  }

  .dropdown-menu {
    --bs-dropdown-border-color: #{$color-gray-200};
    --bs-dropdown-color: #{$color-default-white};
    --bs-dropdown-link-color: #{$color-default-white};
    --bs-dropdown-bg: #{$color-gray-800};
    --bs-dropdown-link-disabled-color: #{$color-gray-600};
  }

  // Bootstrap Overrides (The Wrong but ONLY way)
  .form-control,
  .form-control:focus {
    /* Not using CSS Vars Yet */
    background: #{$color-gray-800};
    border-color: #{$color-gray-600};
    color: #{$color-default-white};
  }
  .form-control::placeholder {
    color: #{$color-gray-600};
  }

  .form-control:focus {
    border-color: #{$color-emphasis};
    box-shadow: none;
  }

  .form-control:disabled,
  .form-control[readonly] {
    background: #{$color-gray-700};
    border-color: #{$color-gray-700};
    color: #{$color-gray-400};
  }
}

.app-wrapper {
  width: 100vw;
  overflow: hidden;
  font-family: var(--font-roboto);
}

.app-wrapper-without-top-navigation {
  height: calc(100vh - $app-header-height);
  width: 100%;
  overflow-y: auto;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.app-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  box-shadow: $box-shadow-default;
  z-index: 10;
}

.app-main-content-wrapper {
  margin: 32px;
}

.app-default-padding {
  padding: 0px 1.5rem;
}

.app-badge {
  font-family: $font-family-sfmono;
  min-height: 20px;
  padding: 3px 8px;
  border-radius: 10px;
  background: $color-font-card;
  margin-right: 5px;
  font-size: $font-size-small;
  font-weight: 400;
  color: $color-default-white;
}
.text-truncate {
  white-space: normal;
  margin-right: var(--chakra-space-4);
}
.flex {
  display: flex;
}

.no-margin {
  margin: 0;
}
.flex-acenter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chakra-tabs {
  .chakra-tabs__tablist {
    border-bottom: 1px solid;
    border-color: inherit;
    button {
      color: $color-font-card;
      font-family: $font-family-regular;
      font-size: $font-size-reg;
      line-height: 24px;
      font-weight: 400;
      padding: 5px 10px 15px 10px;
      margin-right: 10px;
    }
    button:hover {
      background-color: $color-sidebar-hover-bg;
    }
    button[aria-selected="true"] {
      font-family: $font-family-bold;
      font-weight: $font-weight-semi-bold;
      border-bottom: 4px solid $color-nav-selected;
      border-radius: 8px 8px 0 0;
      color: $color-default-font-color;
    }
  }
  .chakra-tabs__tab-panel {
    padding: 0;
  }
}
.chakra-popover__popper {
  z-index: 10001;
}
.text-danger {
  color: $color-default-danger;
  font-weight: 700;
}

.display-none {
  display: none;
}

.display-block {
  display: block;
}

.cp {
  cursor: pointer;
}
.chakra-checkbox {
  .chakra-checkbox__control {
    width: 1.6rem;
    height: 1.6rem;
    align-self: center;
    border-radius: 0.4rem;
    border-color: $color-font-card;
    &.chakra-checkbox__control[data-checked] {
      background-color: $color-link-hover-color;
      border-color: $color-link-hover-color;
    }
    svg {
      font-size: 1rem;
    }
  }
  .chakra-checkbox__label {
    font-size: $font-size-reg;
    max-width: 200px;
  }
}

.text-bold {
  font-weight: bold;
}

#chakra-toast-manager-bottom {
  bottom: 100px !important;
}

.font-size-12px {
  font-size: $font-size-xs;
}

.font-size-18px {
  font-size: $font-size-med;
}

.font-size-20px {
  font-size: $font-size-2rem;
}

.font-size-24px {
  font-size: $font-size-l;
}

.mt-16 {
  margin-top: var(--chakra-space-16);
}
.app-form-field-sub-heading {
  .form-label-container {
    .form-label {
      @extend .font-size-20px;
      line-height: $line-height-large;
    }
  }
}

.app-heading-box {
  border: 1px solid $tag-notstarted-bg-color;
  background-color: $tag-notstarted-bg-color;
  padding: var(--chakra-space-5);
  font-size: $font-size-reg;
  border-top-left-radius: var(--chakra-space-4);
  border-top-right-radius: var(--chakra-space-4);
  margin-bottom: var(--chakra-space-12);
}

.app-heading-box-contents {
  padding: var(--chakra-space-10);
  padding-top: 0px;
  border-bottom: 1px solid $color-border;
  &.last-item {
    border-color: $color-gradient-light-orange;
  }
}

.portal-container-width-auto {
  .chakra-modal__content-container {
    width: auto !important;
    z-index: 100;
  }
}

// Styling for timeout modal

.fade.modal-backdrop.show {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
  background: $color-modal-bg;
  z-index: 10001;
}
.fade.modal.show {
  height: 100vh;
  padding: var(--chakra-space-8);
  margin-top: var(--chakra-space-8);
  min-width: 500px;
  display: flex;
  width: 100vw;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 10001;
  display: flex !important;
  justify-content: center;
  align-items: center;
  overflow: auto;
  overscroll-behavior-y: none;
  outline: none;
}
.modal-dialog.modal-dialog-centered {
  display: flex;
  flex-direction: column;
  background: $color-default-white;
  border-radius: 15px;

  .modal-content {
    padding: var(--chakra-space-9-6);
    max-height: 80vh;
    border-radius: var(--chakra-space-6-4);
    .modal-body {
      padding-bottom: var(--chakra-space-6-4);
      overflow-y: auto;
      h2 {
        font-size: $font-size-l;
        font-weight: 600;
      }
      p {
        margin-top: var(--chakra-space-4-8);
      }
    }
    .modal-footer {
      min-height: var(--chakra-space-28);
      border-color: $color-border;
      display: flex;
      justify-content: flex-end;
      .ms-auto {
        display: flex;
        gap: 1rem;
        .btn.btn-primary {
          min-height: 4.6rem;
          padding: var(--chakra-space-2-4) var(--chakra-space-8);
          border-radius: 1rem;
          font-size: $font-size-reg;
          font-weight: 700;
          border: 1px solid transparent;
          text-transform: capitalize;
          background-color: $color-default-font-color;
          border-color: $color-default-white;
          color: $color-default-white;
        }
        .btn-secondary {
          background: transparent;
          color: $color-link-hover-color;
          text-decoration: none;
          font-size: $font-size-reg;
          font-weight: 700;
          word-break: break-word;
          text-align: left;
          padding-left: 0;
          padding-right: 0;
          border: none;
        }
      }
    }
  }
}

.banner {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: $color-surface-blue-light;
  padding: var(--chakra-space-4) var(--chakra-space-6);
}
pre {
  white-space: pre-line;
}

.chakra-card__header {
  padding: 0;
}
