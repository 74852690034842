@import "mixins";

//app font sizes
$font-size-xxl: 4.8rem;
$font-size-xl: 3.6rem;
$font-size-32: 3.2rem;
$font-size-l: 2.4rem;
$font-size-2rem: 2rem;
$font-size-med: 1.8rem;
$font-size-reg: 1.6rem;
$font-size-small: 1.4rem;
$font-size-xs: 1.2rem;
$font-base-size: 10px;
$font-size-xxs: 0.8rem;

// Font weights
$font-weight-bold: 700;
$font-weight-semi-bold: 600;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-size-12px: $font-size-xs;
$font-size-14px: $font-size-small;
$font-size-11px: 1.1rem;

//app font family
$font-family-regular: var(--font-roboto);
$font-family-semi-bold: var(--font-roboto);
$font-family-bold: var(--font-roboto);
$font-family-sfmono: var(--font-roboto-mono);
$font-family-sfmono-semi-bold: var(--font-roboto-mono);
$font-family-sfmono-medium: var(--font-roboto-mono);

// Line heights
$line-height-xxlarge: 40px;
$line-height-xlarge: 32px;
$line-height-small: 20px;
$line-height-large: 28px;
$line-height-medium: 24px;
$line-height-xs: 16px;

$space-rem-section: 10rem;
$space-rem-xlarge: 4rem;
$space-rem-large: 2rem;
$space-rem-medium: 1.5rem;
$space-rem-base: 1rem;
$space-rem-small: 0.625rem;

//Catalogs
$catalog-min-width: 217px;
$catalog-mid-width: 275px;
$catalog-max-width: 500px;
$catalog-left-padding: 820px;
$catalog-expanded-width: 130%;
$catalog-screen-padding-min: 30px;
$catalog-screen-padding-max: 425px;

//Screen Sizes
$screen-width-breakpoint-1: 1727px;
$screen-width-breakpoint-2: 1330px;

//border attributes
$border-radius-xs: 4px;
$border-radius-small: 8px;
$border-radius-medium: 10px;
$border-radius-16: 16px;
$border-radius-large: 20px;
$border-radius-xlarge: 24px;
$border-color: #e0e0e0;

//padding, margin and gaps
$gap-xs: 8px;
$gap-small: 10px;
$gap-medium: 15px;
$padding-none: 0px;
$padding-xxs: 4px;
$padding-small: 10px;
$padding-medium: 15px;
$padding-large: 20px;
$padding-xl: 24px;
$padding-xxl: 32px;

// Aspect ratios
$aspect-ratio-image-card: 1 / 1;

//Course Image dimensions
$course_img_width: 150px;
$course_img_height: 150px;

// Payment Window
$field_height: 50px;
$icon_height: 100px;
$icon_width: 100px;

// Sidebar dimensions
$sidebar-default-width: 235px;

//default app colors
$color-primary: blue;
$color-bg-default: #f8fbff;
$color-default-font-color: #26292e;
$color-default-white: #ffffff;
$color-default-black: #000000;
$color-default-danger: #d51027;
$color-default-success: #0b8449;
$color-default-warning: #fac213;
$color-form-field-placeholder-txt: #8f9297;

// Grays (bootstrap)
$color-gray-0: #18191c;
$color-gray-100: #f8f9fa;
$color-gray-200: #e9ecef;
$color-gray-300: #dee2e6;
$color-gray-400: #ced4da;
$color-gray-500: #adb5bd;
$color-gray-600: #6c757d;
$color-gray-700: #495057;
$color-gray-800: #343a40;
$color-gray-900: #212529;

// FAST Blue
$fast-blue-1: #1c4e94;
$fast-blue-2: #0563e6;
$fast-blue-3: #7ab6f5;
$fast-blue-4: #e6f0fe;
$fast-blue-5: #f8fbff;

// FAST Gray
$fast-gray-0: #18191c;
$fast-gray-1: #26292e;
$fast-gray-2: #454d56;
$fast-gray-3: #8f9297;
$fast-gray-4: #d3d4d5;
$fast-gray-5: #efefef;
$fast-gray-6: #cccccc;

$fast-icon-0: #1c1b1f;

$color-description-txt: $color-form-field-placeholder-txt;
$color-nav-hover: #202020;
$color-nav-selected: #fb6400;
$color-search-btn-hover: #0000000f;
$color-sidebar-hover-bg: #e6f0fe;
$color-sidebar-seleted-bg: #d3e6ff;
$color-modal-bg: #00000066;
$color-emphasis: #71bacb;

$color-link-hover-color: #0563e6;
$color-link-color: #0a6efa;
$color-divider: $color-form-field-placeholder-txt;
$color-bg-badge: #efefef;
$color-font-card: #454d56;
$toggle-not-checked: #d3d3d3;
$color-font-product-name: $color-form-field-placeholder-txt;
$color-btn-primary: #961edf;
$color-btn-primary-hover: #7b19b8;
$color-btn-inverse-primary-hover: #eedcf9;
$color-btn-secondary-hover: #e6f0fe;
$color-btn-link-hover: #1c4e94;
$color-border: #d3d4d5;
$color-border-error: #d51027;
$color-error-bg: #ffe7e9;
$color-user-tag-color: #d6e2f3;
$color-toast-box-shadow: rgba(0, 0, 0, 0.3);
$color-toast-success-notification: #0b8449;
$color-yellow-toast-notification: #ff8606;
$color-dark-grey: #505050;
$color-red-toast-notification: $color-default-danger;
$tag-label-pink: #702459;
$tag-label-gray: #1a202c;
$tag-label-cyan: #086f83;
$tag-label-yellow: #744210;
$tag-style-pink: #fed7e2;
$tag-style-gray: #edf2f7;
$tag-style-cyan: #c4f1f9;
$tag-style-yellow: #fefcbf;
$color-bg-badge-success: #edfdf2;
$color-font-badge-success: $color-toast-success-notification;
$color-bg-badge-error: #ffe7e9;
$color-bg-badge-warning: #ffefbf;
$color-font-badge-warning: #967103;
$color-border-blue: $color-link-hover-color;
$color-radio-button-color: $color-link-hover-color;
$color-radio-button-outline: rgba(80, 80, 200, 0.2);
$color-radio-button-selected: #d9d9d9;
$color-progress-bar-disabled-bgcolor: #f9b385;
$color-box-shadow: #0000004d;
$box-shadow-default: 0px 4px 14px 0px $color-box-shadow;
$color-box-shadow-tooltip: rgba(0, 0, 0, 0.2);
$color-icon-deactivated: gray;
$color-stepper-active-tag: #ff870644;
$color-stepper-dotted-lines: #0000006b;
$color-form-field-bg: #f8f8f8;
$color-stepper-indicator: #454d56;
$box-shadow-status-sidebar: #0000001a;
$color-stepper-border: #929496;
$color-stepper-status-inprogress: #fac213;

$color-toast-success-highlight: #0b8449;
$color-toast-success: #f2f8f5;
$color-yellow-toast: #fffcf2;
$color-toast-info-notification: #efefef;
$color-toast-message-notification: #e6f0fe;
$color-box-shadow-title: #00000014;
$color-inline-code-background: #454d561a;
$color-inline-code-text: #f4592dcc;

//gradient colors
$color-gradient-dark-purple: #280e55d6;
$color-gradient-light-purple: #7f1ebbde;
$color-gradient-light-orange: #fb6400;
$color-gradient-main-banner: linear-gradient(
  90deg,
  $color-gradient-dark-purple 1.56%,
  $color-gradient-light-purple 59.9%,
  $color-gradient-light-orange 100%
);

//form colors
$color-form-field-border: $color-font-card;

// calendar colors
$calendar-overlay-border: #adadad;
$calendar-input-border: #808080;

//Common Title
$color-main-title: $color-default-font-color;
$color-version-subtext: $color-font-card;

//Common Title
$color-main-title: $color-default-font-color;
$color-version-subtext: $color-font-card;

//User avatar
$color-user-avatar-background: #e65000;

$color-check-background: #1c1b1f;
$color-main-bg-color: #f9fbff;

$color-border-hover: #7ab6f5;

// Tag styles
$tag-completed: "COMPLETED";
$tag-in-progress: "IN PROGRESS";
$tag-not-started: "NOT STARTED";

$view-button-not-started-text: "Launch";
$view-button-completed-text: "View";

$tag-completed-text-color: #0b8449;
$tag-completed-bg-color: #edfdf2;
$view-button-completed-bg-color: #ffffff;

$tag-inprogress-text-color: #454d56;
$tag-disabled-text-color: #c3c7cb;
$tag-inprogress-bg-color: #ffefbf;
$view-button-inprogress-bg-color: #fcba03;

$tag-notstarted-text-color: #26292e;
$tag-notstarted-bg-color: #efefef;
$view-button-notstarted-bg-color: #fcba03;

//Other
$shadow-box-default: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);

// Tag styles
$tag-completed: "COMPLETED";
$tag-in-progress: "IN PROGRESS";
$tag-not-started: "NOT STARTED";

$view-button-not-started-text: "Launch";
$view-button-completed-text: "View";

$tag-completed-text-color: #0b8449;
$tag-completed-bg-color: #edfdf2;
$tag-inprogress-bg-color: #ffefbf;
$tag-notstarted-bg-color: #efefef;
$tag-notstarted-text-color: #26292e;

$view-button-completed-bg-color: $color-default-white;
$view-button-inprogress-bg-color: #fcba03;

$tag-notstarted-text-color: #26292e;
$view-button-notstarted-bg-color: #fcba03;

//Other
$shadow-box-default: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);

//indices
$indices-xl: 9999;

//font-weights
$font-weight-600: 600;
$font-weight-400: 400;
$font-weight-700: 700;

$color-border-hover: #7ab6f5;
$color-surface-blue-light: #e6f0fe;
$color-surface-text-blue: #0563e6;
// Multi Tiered Side Menu
$multi-tiered-side-menu-max-width: 235;
$multi-tiered-side-menu-max-width-px: 235px;

// Generic component sizes
$app-header-height: 63px;
$app-footer-height: 21px;
$config-form-header-height: 131px;
$prospect-banner-height: 45px;
$select-control-height: 46px;
$select-option-min-height: 40px;
$app-plain-header-height: 110px;
$config-form-footer-height: 78px;

$color-footer-background: #efefef;

$zIndexTopNavigation: 1001;
$zIndexSideNavigation: 1998;

// Breakpoints
$breakpoint-tablet-desktop: 75rem; // 1200px / 16px
$breakpoint-mobile-desktop: 767px; // use with max-width

// Z-index
$modal-z-index: 14001;
