.hero {
    &__firstLine {
      color: $color-primary;
       
    }
  
    &__descriptiveText {
        color: $color-primary
    }
  }
  