@import "./variables";

.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.app-btn-default {
  min-height: 4.6rem;
  padding: 6px 20px;
  border-radius: 1rem;
  font-size: 1.6rem;
  font-weight: 700;
  border: 1px solid transparent;
  text-transform: capitalize;
  &:disabled {
    @extend .disabled;
  }
}

// Solid purple, white text
.app-btn-reg-primary {
  @extend .app-btn-default;
  color: $color-default-white;
  background-color: $color-btn-primary;
  &:hover {
    background-color: $color-btn-primary-hover !important;
  }
}

// Purple text, no outline
.app-btn-inverse-primary {
  @extend .app-btn-default;
  background-color: $color-default-white;
  color: $color-btn-primary;
  &:hover {
    background-color: $color-btn-inverse-primary-hover;
  }
}

// Blue text, blue outline
.app-btn-reg-secondary {
  @extend .app-btn-default;
  background-color: $color-default-white;
  color: $color-link-hover-color;
  border-color: $color-link-hover-color;
  &:hover {
    background-color: $color-btn-secondary-hover !important;
    border-color: $color-link-hover-color !important;
  }
}

// Solid blue, white text
.app-btn-reg-secondary-selected {
  @extend .app-btn-default;
  color: $color-default-white;
  background-color: $color-link-hover-color;
  &:hover {
    background: $color-link-hover-color;
    opacity: 0.8;
  }
}

// Dark w/ white outline
.app-btn-inverse-secondary {
  @extend .app-btn-default;
  background-color: $color-default-font-color;
  border-color: $color-default-white;
  color: $color-default-white;
  &:hover {
    background-color: $color-default-font-color !important;
    box-shadow: 1px 1px 14px 4px $box-shadow-status-sidebar;
    opacity: 0.8;
  }
  a {
    text-transform: initial;
  }
}

// Blue text, no outline
.app-btn-link {
  background: transparent;
  color: $color-link-hover-color;
  text-decoration: none;
  font-size: 1.6rem;
  font-weight: 700;
  word-break: break-word;
  text-align: left;
  padding-left: 0px;
  padding-right: 0px;
  justify-content: flex-start;
  &:hover {
    color: $color-btn-link-hover !important;
    background: transparent !important;
  }
}

.app-btn-link-underline {
  @extend .app-btn-link;
  text-decoration: underline !important;
}

// Blue outline, transparent background
.app-btn-reg-secondary-transparent {
  @extend .app-btn-default;
  background-color: transparent;
  color: $color-link-hover-color;
  border-color: $color-link-hover-color;
  &:hover {
    background-color: $color-btn-secondary-hover !important;
    border-color: $color-border-hover !important;
    box-shadow: 0 4px 14px 4px $box-shadow-status-sidebar;
  }
}

.configured-product-footer {
  .footer-btn-configuration {
    & > button:not(:last-child) {
      margin-right: 1.5rem;
    }
  }
}

.app-btn-inverse-secondary-add-new {
  @extend .app-btn-inverse-secondary;
  text-transform: none;
}

// Dark gray solid, white text, no outline
.app-btn-inverse-secondary-get-started {
  @extend .app-btn-inverse-secondary;
  font-family: $font-family-regular;
  font-size: $font-size-reg;
  font-weight: $font-weight-bold;
  line-height: var(--chakra-lineHeights-7);
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: none;
}

// Solid white dark text
.app-btn-solid-white {
  min-height: var(--chakra-space-18-4);
  padding: var(--chakra-space-2-4) var(--chakra-space-8);
  border-radius: 1rem;
  font-size: $font-size-reg;
  font-weight: var(--chakra-fontWeights-bold);
  border: 1px solid transparent;
  white-space: normal;
  background-color: $color-default-white;
  border-color: $color-default-black;
  color: $color-default-black;
}